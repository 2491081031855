import { useRouter } from 'next/router';

import SeoHead from '@/components/chrome/seo-head/SeoHead';
import Button from '@/components/form/button/Button';
import PageHeader from '@/components/typography/page-header/PageHeader';
import SmartImage from '@/components/image/SmartImage';
import { IconType } from '@/components/icon/Icon';

const Custom404 = () => {
    const router = useRouter();

    const goBack = () => router.back();
    return (
        <>
            <SeoHead title='404' />
            <div className='error-page'>
                <div className='__container'>
                    <SmartImage
                        src='/images/global/festgps_head_only_white.png'
                        alt='FestGPS'
                        width='150'
                        height='150'
                        priority
                    />
                    <PageHeader
                        h1='404'
                        subHeading={`Sorry, we can't find what you're looking for ...`}
                        textAlign='center'
                    />
                    <Button
                        theme='primary'
                        label='Back'
                        onPress={goBack}
                        leftIcon={IconType.ChevronLeft}
                    />
                </div>
            </div>
        </>
    );
};

export default Custom404;
